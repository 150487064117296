.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

main {
  margin: auto;
  width: 100%;
}

@media screen and (max-width: 600px) {
  body {
    background-color: olive;
    background-color: rgb(231, 232, 214);
  }
  .card-img {
    min-width: 80%;
    height: 60%;
    margin-top: 2px;
    border-radius: 0.5rem;
    background-size: contain;
  }

  .link-card {
    width: 85%;
    height: 420px;
    margin: 10px;
    background-color: aquamarine;
  }
}

@media screen and (min-width: 601px) {
  body {
    background-color: blue;
    color: white;
  }

  .link-card {
    width: 26%;
    height: 430px;
    margin: 20px;
    background-color: aquamarine;
  }

  .card-img {
    min-width: 80%;
    max-width: 90%;
    height: 65%;
    margin-top: 2px;
    border-radius: 0.5rem;
    background-size: contain;
    background-position: 100%;
  }
}

@media screen and (min-width: 1200px) {
  body {
    background-color: rgba(236, 237, 222, 0.999);
  }
  .card-img {
    min-width: 250px;
    max-width: 250px;
    height: 70%;
    margin-top: 5px;
    border-radius: 0.5rem;
    background-size: contain;
    background-position: 100%;
  }

  .link-card {
    list-style: none;
    background-color: white;
    background-image: var(--accent-gradient);
    border-radius: 0.5rem;
    background-position: 100%;
    transition: background-position 0.6s cubic-bezier(0.22, 1, 0.36, 1);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -2px rgba(0, 0, 0, 0.1);
    width: 26%;
    height: 430px;
    margin: 20px;
    background-color: aquamarine;
  }
}

