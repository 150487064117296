@media screen and (min-width: 1024px) {
  .recipeParent {
    background-color: rgb(202, 202, 194);
    width: 75%;
  }
  .recipePhoto {
    max-width: 400px;
  }
}

@media screen and (max-width: 1023px) and (min-width: 320px) {
  .recipeParent {
    background-color: rgb(200, 152, 200);
    width: 90%;
  }
  .recipePhoto {
    max-width: 400px;
  }
}

@media screen and (max-width: 320px) {
  .recipeParent {
    background-color: olive;
    width: 95%;
    min-width: 300px;
  }
  .recipePhoto {
    max-width: 310px;
  }
  .recipeList {
    margin: 15px;
    padding-left: 20px;
    margin-bottom: 15px;
  }
}

.recipeParent {
  margin: auto;
  padding: 40px;
  opacity: 95%;
  border-radius: 1%;
  font-family: "Montserrat", sans-serif;
  font-size: 19px;
}

.ulStyle {
  line-height: 2em;
}

.recipeTitle {
  margin-bottom: 15px;
  font-size: 25px;
  justify-content: center;
  display: flex;
}

.recipePhoto {
  margin: auto;
  height: auto;
  border-radius: 2%;
}

.description {
  margin-top: 15px;
}

.INname {
  font-size: 25px;
}

// how to consise margins within?
