.TabsContainer {
    font-family: 'Montserrat', sans-serif;
    padding-top: 10%;
    margin-bottom: 10px;
    margin-left: 20px;
}

a:visited {
    color: black !important;
}
