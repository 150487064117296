@media screen and (min-width: 1024px) {
  .header {
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  .empty {
    grid-area: 1 / 1 / 3 / 2;
  }

  .starters {
    grid-area: 2 / 3 / 3 / 4;
  }
  .soups {
    grid-area: 2 / 4 / 3 / 5;
  }
  .salads {
    grid-area: 2 / 5 / 3 / 6;
  }
  .mains {
    grid-area: 2 / 6 / 3 / 7;
  }
  .desserts {
    grid-area: 2 / 7 / 3 / 8;
  }
  .tipsAndTricks {
    grid-area: 2 / 8 / 3 / 9;
  }

  .webName {
    grid-area: 1 / 7 / 2 / 10;
  }


}

@media screen and (max-width: 1023px) and (min-width: 320px) {
  .header {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 200px repeat(3, 35px);
    padding: 20px;
  }

  .starters {
    grid-area: 2 / 2 / 3 / 3;
  }
  .soups {
    grid-area: 3 / 2 / 4 / 3;
  }
  .salads {
    grid-area: 4 / 2 / 5 / 3;
  }
  .mains {
    grid-area: 2 / 4 / 3 / 5;
  }
  .desserts {
    grid-area: 3 / 4 / 4 / 5;
  }
  .tipsAndTricks {
    grid-area: 4 / 4 / 5 / 5;
  }

  .webName {
    grid-area: 1 / 3 / 2 / 6;
  }
}

@media screen and (max-width: 320px) {
  .header {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(7, 1fr);
  }

  .starters {
    grid-area: 2 / 2 / 3 / 3;
  }
  .soups {
    grid-area: 3 / 2 / 4 / 3;
  }
  .salads {
    grid-area: 4 / 2 / 5 / 3;
  }
  .mains {
    grid-area: 5 / 2 / 6 / 3;
  }
  .desserts {
    grid-area: 6 / 2 / 7 / 3;
  }
  .tipsAndTricks {
    grid-area: 7 / 2 / 8 / 3;
  }

  .webName {
    grid-area: 1 / 2 / 2 / 4;
  }
}

.header {
  width: 100%;
  margin-bottom: 120px;

  display: grid;
  background-color: rgb(202, 202, 194);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  font-family: "Montserrat", sans-serif;
}

a {
  margin: 0px;
  color: rgb(31, 31, 31);
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

h1 {
  color: rgb(31, 31, 31);
  text-align: center;
  font-size: 40px;
  grid-area: 1 / 6 / 2 / 9;
  height: 50%;
  margin-top: 20px;
}
