a {
    grid-area: 1 / 1 / 2 / 2;
  }
  
  .logoImage {
    width: 130px;
    height: auto;
    margin-left: 20px;
    margin-top: 20px;
  }
  