.link-card {
  list-style: none;
  background-color: white;
  background-image: var(--accent-gradient);
  border-radius: 0.5rem;
  background-position: 100%;
  transition: background-position 0.6s cubic-bezier(0.22, 1, 0.36, 1);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -2px rgba(0, 0, 0, 0.1);
  text-align: left;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-family: 'Montserrat', sans-serif;
  height: 450px;

}

.link-card > a {
  width: 100%;
  text-decoration: none;
  border-radius: 0.35rem;
  color: #111;
  background-color: rgb(202, 202, 194);
}

.clickable {
  padding: 0%;
  margin: 0%;
  display: flex;
  flex-direction: column;
  height: 100%;
}


.titleFont {
  font-size: 19px;
  width: 75%;
}

.blurbFont {
  font-size: 16px;
  width: 75%;
  height: 72px;
  padding-top: 2px;
}

.card-img {
  max-width: 75%;
  min-width: 75%;
  max-height: 55%;
  min-height: 55%;
}

.none {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

li.link-card {
  width: 100%;
}
