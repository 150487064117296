.table {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;

  background-image: var(--accent-gradient);
  border-radius: 0.5rem;
  background-position: 100%;
  transition: background-position 0.6s cubic-bezier(0.22, 1, 0.36, 1);
  text-align: left;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: auto;
  justify-content: space-around;
  font-family: "Montserrat", sans-serif;
}

.tableParent {
    padding: 0%;
    margin: auto;
}

.cardClass {
  width: 31%;
  padding: 0%;
  margin: auto;
  display: flex;
}
